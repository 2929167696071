<template>
    <div class="random">
        <div class="random-left">
            <div>
                <span class="title">随机密码生成</span>
            </div>
            <el-row class="line">
                <el-col :span="24">
                    <table class="password-table">
                        <tr>
                            <td>所用字符</td>
                            <td colspan="3">
                                <el-checkbox-group v-model="checkList" @change="checkListChange">
                                    <el-checkbox label="a-z"></el-checkbox>
                                    <el-checkbox label="A-Z"></el-checkbox>
                                    <el-checkbox label="0-9"></el-checkbox>
                                    <el-checkbox label="!@#"><input type="text" :disabled="ss_fh_disp"
                                                                    :value="ss_fh_value" style="width: 60px"/>
                                    </el-checkbox>
                                </el-checkbox-group>
                            </td>
                        </tr>
                        <tr>
                            <td>排除字符</td>
                            <td colspan="3">
                                <el-checkbox style="display: inline" v-model="checked"></el-checkbox>&nbsp;
                                <el-input style="width: 100px " v-model="excludeStr"
                                          placeholder="请输入排除内容" :disabled="checked"></el-input>
                            </td>
                        </tr>
                        <tr>
                            <td>密码长度</td>
                            <td>
                                <el-select v-model="pwdLength" placeholder="请选择" style="width: 66px">
                                    <el-option v-for="item in 99" :key="item" :label="item" :value="item"/>
                                </el-select>
                                位
                            </td>
                            <td>密码数量</td>
                            <td>
                                <el-select v-model="pwdNum" placeholder="请选择" style="width: 66px">
                                    <el-option v-for="item in 99" :key="item" :label="item" :value="item"/>
                                </el-select>
                                个
                            </td>
                        </tr>
                        <tr>
                            <td colspan="4" style="text-align: center">
                                <el-button type="primary" @click="randomPassword"
                                           :disabled="!checkList.length>0">生成密码
                                </el-button>
                            </td>
                        </tr>
                        <tr v-if="pwdtextarea!=''">
                            <td colspan="4" style="text-align: center">
                                <el-input type="textarea" :rows="pwdNum" placeholder="请输入内容"
                                          v-model="pwdtextarea" id="passwordTextCopy"/>
                            </td>
                        </tr>
                        <tr v-if="pwdtextarea!='' && pwdNum==1">
                            <td colspan="4">
                                {{ mimaqiangdu }}
                                <el-button type="primary" size="small" style="float: right;" @click="copyClick">
                                    复制
                                </el-button>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="3">
                                <el-checkbox v-model="recordHistory"></el-checkbox>
                                记录历史记录
                            </td>
                            <td v-if="viewHistory" @click="viewHistoryEvent">查看历史记录<i
                                    class="el-icon-arrow-down"></i></td>
                            <td v-else @click="viewHistoryEvent">隐藏历史记录<i class="el-icon-arrow-up"></i>
                            </td>
                        </tr>
                        <tr v-if="viewHistory">
                            <td colspan="4">该功能将记录您最近生成的1000个密码。并且历史记录不是存储在我们云端服务器上，而是存储在您的浏览器本地存储中。
                                <el-link type="danger" @click="clearHistory">[ 清空记录 ]</el-link>
                            </td>
                        </tr>
                        <tr v-show="viewHistory" v-for="(item, index) in historyList" :key="index">
                            <td colspan="4"><span
                                    style="display: inline-block;width: 180px">{{ item.password }}</span> <span
                                    style="display: inline-block;float: right">{{ item.time }}</span></td>
                        </tr>
                    </table>
                </el-col>
            </el-row>
            <el-row style="margin-top: 20px" v-if="adSwitch">
                <el-col :span="24" style="text-align: center">
                    <div class="ad" style="height: 90px;text-align: center;"></div>
                </el-col>
            </el-row>
            <el-row style="margin-top: 20px">
                <el-col :span="24" style="text-align: center">
                    <el-image :src="pwd_img_url" width="95%" style="max-width: 550px;border-radius: 10px;"></el-image>
                </el-col>
            </el-row>
            <el-row class="about">
                <el-col :span="24">
                    <el-collapse v-model="activeNames" @change="handleChange">
                        <el-collapse-item title="关于密码生成器" name="1">
                            <div>
                                密码是一种用来混淆的技术，它希望将正常的（可识别的）信息转变为无法识别的信息。当然，对一小部分人来说，这种无法识别的信息是可以再加工并恢复的。密码在中文里是“口令”（password）的通称。登录网站、电子邮箱和银行取款时输入的“密码”其实严格来讲应该仅被称作“口令”，因为它不是本来意义上的“加密代码”，但是也可以称为秘密的号码。
                            </div>
                        </el-collapse-item>
                    </el-collapse>
                </el-col>
            </el-row>
        </div>
        <div class="random-right">
            <div style=" height: 250px; margin-bottom: 8px; text-align: center; " v-if="adSwitch">

            </div>
            <div>
                <el-card class="box-card">
                    <div slot="header" class="clearfix">
                        <span>推荐工具</span>
                    </div>
                    <div v-for="(item,index) in recommendList" :key="index" style="margin-bottom: 10px">
                        <a :href="item.url" target="_blank" style="color: #3a8ee6;">{{ item.name }}</a>
                    </div>
                </el-card>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    metaInfo: {
        title: '生成随机密码 - 密码生成器 - 密码批量生成器',
        meta: [
            {
                charset: "utf-8"
            },
            {
                name: 'description,',
                content: '生成随机密码,密码生成器。'
            },
            {
                name: 'keywords',
                content: '生成随机密码,密码生成器'
            }],
        link: [
            {
                rel: 'canonical',
                href: 'https://www.itptg.com/'
            }
        ]
    },
    data() {
        return {
            mimaqiangdu: '',
            leftSpan: 16,
            rightSpan: 8,
            adSwitch: false,
            recommendList: [
                {'name': '随机密码生成', 'url': '/'},
                {'name': '利息计算器', 'url': '/invest/'},
                {'name': '成本计算器', 'url': '/stockcost/'},
                {'name': '编码转换', 'url': '/stockcost/'}
            ],
            pwd_img_url: require("@/assets/images/img.png"),
            activeNames: ['1'],
            method: "随机数",
            checkList: ['a-z', 'A-Z', '0-9'],
            checked: true,
            excludeStr: 'iIl1o0O',
            pwdLength: 16,
            pwdNum: 1,
            viewHistory: true,
            recordHistory: true,
            historyList: [],
            ss_fh_value: '!@#$%^&*',
            ss_fh_disp: true,
            pwdtextarea: '',
            layout: 'pc',
            windowHeight: document.body.clientHeight,
            windowWidth: document.body.clientWidth
        }
    },
    computed: {},
    methods: {
        viewHistoryEvent() {
            this.viewHistory = !this.viewHistory
        },
        randomPassword() {
            let confusingStr = ''
            let newConfusingStr = this.processString(confusingStr, this.checkList)
            if (!this.ss_fh_disp) {
                newConfusingStr += this.ss_fh_value
            }
            let pwdtextareaStr = ''
            // console.log(newConfusingStr)
            for (let i = 0, s = this.pwdNum; i < s; i++) {
                let password = this.generatePassword(this.pwdLength, newConfusingStr)
                // console.log(password)
                // console.log(this.showPwd(password))
                this.mimaqiangdu = this.showPwd(password)
                if (this.recordHistory) {
                    this.localPassWord(password)
                }
                if (s - i > 1) {
                    pwdtextareaStr += password + '\n'
                } else {
                    pwdtextareaStr += password
                }
            }
            this.pwdtextarea = pwdtextareaStr
            this.getHistoryShow()
        },
        localPassWord(pwd) {
            let passwordLocal = localStorage.getItem("passwordLocal")
            if (passwordLocal != null && passwordLocal != '') {
                let passwordLocalList = JSON.parse(passwordLocal)
                passwordLocalList.unshift({'password': pwd, 'time': this.getDate()})
                localStorage.setItem("passwordLocal", JSON.stringify(passwordLocalList))
            } else {
                localStorage.setItem("passwordLocal", JSON.stringify([{'password': pwd, 'time': this.getDate()}]))
            }
        },
        getDate() {
            // 补零
            let addZero = (t) => {
                return t < 10 ? '0' + t : t;
            }
            let time = new Date();
            let Y = time.getFullYear(), // 年
                M = time.getMonth() + 1, // 月
                D = time.getDate(), // 日
                h = time.getHours(), // 时
                m = time.getMinutes(), // 分
                s = time.getSeconds(); // 秒
            if (M > 12) {
                // 注: new Date()的年月日的拼接，在月份为12月时，会出现 获取月份+1 后，为13月的bug，需要特殊处理。用moment第三方插件获取时间也可避免此问题。
                M = M - 12;
            }
            return `${Y}-${addZero(M)}-${addZero(D)} ${addZero(h)}:${addZero(m)}:${addZero(s)}`;
        },
        generatePassword(length = 16, includeChars = 'abcdefghijklmnopqrstuvwxyzABCDEFGHJKLMNPQRSTUVWXYZ0123456789!@#$%^&*') {
            // 排除指定字符
            const excludedChars = this.checked ? this.excludeStr : '';
            includeChars = includeChars.split('').filter(char => !excludedChars.includes(char)).join('');
            // 生成密码
            let password = '';
            for (let i = 0; i < length; i++) {
                password += includeChars.charAt(Math.floor(Math.random() * includeChars.length));
            }
            return password;
        },
        showPwd(p) {
            var hasUpper = false;
            var hasLow = false;
            var hasNum = false;
            var hasOther = false;
            for (var i = 0; i < p.length; i++) {
                var c = p.charCodeAt(i);
                if (c >= 65 && c <= 90) hasUpper = true;
                else if (c >= 97 && c <= 122) hasLow = true;
                else if (c >= 48 && c <= 57) hasNum = true;
                else hasOther = true;
            }
            var pwdNum = 0;
            if (hasUpper) pwdNum += 26;
            if (hasLow) pwdNum += 26;
            if (hasNum) pwdNum += 10;
            if (hasOther) pwdNum += 32;
            var num = Math.pow(pwdNum, p.length);
            var str = ' 强度:' + pwdNum + ' 破解耗:' + this.formatTime(num / (1024 * 1024 * 1024 * 2.4 * 2));
            return str;
        },
        formatTime(s) {
            var str = '';
            if (s < 1) return '小于1秒！';
            s = Math.floor(s);
            if (s >= 1) //str = s % 60 + '秒' + str;
                s = Math.floor(s / 60);
            if (s >= 1) //str = s % 60 + '分' + str;
                s = Math.floor(s / 60);
            if (s >= 1) //str = s % 24 + '时' + str;
                s = Math.floor(s / 24 / 365);
            if (s >= 1) str = s + '年' + str;
            return str;
        },
        processString(str, checkList) {
            let result = str;
            // 检查 checkList 数组中是否包含 'a-z'
            if (checkList.includes('a-z')) {
                result = `${result}abcdefghijklmnopqrstuvwxyz`;
            }
            // 检查 checkList 数组中是否包含 '0-9'
            if (checkList.includes('A-Z')) {
                result = `${result}ABCDEFGHJKLMNPQRSTUVWXYZ`;
            }
            if (checkList.includes('0-9')) {
                result = `${result}0123456789`;
            }
            return result;
        },
        checkListChange(e) {
            if (e.includes('!@#')) {
                this.ss_fh_disp = false
            } else {
                this.ss_fh_disp = true
            }
        },
        copyClick() {
            var input = document.getElementById("passwordTextCopy");
            input.value = this.pwdtextarea; // 要复制的文本框的内容（此处是后端返回的内容）
            input.select(); // 选中文本
            document.execCommand("copy");
            this.$message({
                message: '复制成功',
                type: 'success'
            });
        },
        clearHistory() {
            localStorage.removeItem("passwordLocal")
            this.getHistoryShow()
        },
        getHistoryShow() {
            let passwordLocal = localStorage.getItem("passwordLocal")
            if (passwordLocal) {
                this.historyList = JSON.parse(passwordLocal)
            } else {
                this.historyList = []
            }
        },
        handleChange(val) {
            console.log(val);
        }
    },
    created() {
        this.getHistoryShow()
    },
    mounted: function () {
    },
    watch: {}
}
</script>

<style scoped>


@media screen and (min-width: 800px) {
    .random {
        width: 100%;
        text-align: left;
        display: flex;
        justify-content: space-between;
        border: 1px dashed #cccccc;
    }

    .random-left {
        margin: 5px;
        border: 1px solid #000000;
        flex-basis: 67%;
    }

    .random-left .line {
        border-top: 1px solid #000000;
    }

    .random-left .about {
        margin: 10px;
    }

    .title {
        font-weight: 700;
        font-size: 32px;
        display: inline-block;
        width: 100%;
        text-align: center;
        line-height: 80px;
        height: 80px;
    }

    .password-table {
        border-collapse: collapse;
        max-width: 450px;
        margin: 0 auto;
        margin-top: 10px;
        font-size: 12px;
    }

    .password-table td {
        border: 1px solid black;
        padding: 8px;
    }

    .random-right {
        flex-basis: 33%;
        margin: 5px;
    }

}

@media screen and (max-width: 799px) {
    .random {
        width: 100%;
        text-align: left;
        display: flex;
        flex-direction: column;
    }

    .random-left {
        margin: 5px;
        flex-basis: 100%;
    }

    .title {
        font-weight: 700;
        font-size: 32px;
        display: inline-block;
        width: 100%;
        text-align: center;
        line-height: 80px;
        height: 80px;
    }

    .password-table {
        border-collapse: collapse;
        max-width: 450px;
        margin: 0 auto;
        margin-top: 10px;
        font-size: 12px;
    }

    .password-table td {
        border: 1px solid black;
        padding: 8px;
    }

    .random-right {
        display: none;
    }
}
</style>
